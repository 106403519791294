angular
    .module('annexaApp')
    .component('annexaBoxObjectSessionDocuments',{
        templateUrl: './components/sec/annexa-box-object-session-documents/annexa-box-object-session-documents.html',
        require: {
            sessionComponent: '^^annexaBoxObjectSession'
        },
        controller: ['SecFactory', '$filter', '$rootScope', 'ErrorFactory', '$state', '$scope', 'RestService', 'HelperService', 'AnnexaPermissionsFactory', 'GlobalDataFactory', 'Language', 'AnnexaFormlyFactory', 'CommonService', 'DialogsFactory', 'HeaderService', 'AnnexaDocumentActionsButtonFactory', 'TableFilterFactory', 'DccumentsFactory', '$http', 'SecModals', 
        	function (SecFactory, $filter, $rootScope, ErrorFactory, $state, $scope, RestService, HelperService, AnnexaPermissionsFactory, GlobalDataFactory, Language, AnnexaFormlyFactory, CommonService, DialogsFactory, HeaderService, AnnexaDocumentActionsButtonFactory, TableFilterFactory, DccumentsFactory, $http, SecModals) {

        	var vm = this;

            vm.languageColumn = Language.getActiveColumn();
            
            var showStyledDocumentTypeAcronym = $rootScope.app.configuration.show_styled_document_type_acronym.value;
            
            vm.filter = [
                { id: 'code', type: 'text', order: 0, label: 'global.literals.code' },
                { id: 'name', type: 'text', order: 1, label: 'global.literals.title' },
                { id: 'type', type: 'select', order: 2, label: 'global.literals.docType', dataType: 'LOCAL', data: GlobalDataFactory.allDocumentTypes, addAll: true, nameProperty: Language.getActiveColumn() }
            ];

			var getFilterCall = function() {
                var filterCall = {
                    document: TableFilterFactory.getFilterCall(vm.filter),
                    session: { id: vm.sessionComponent.session.id }
                };

                return filterCall;
            }

            var getFilterCallAux = function () {
                return {};
            }
            var documentTypeColumn = new IconFilterColumn($scope, 'filterData', 'document.docStatus',
                [
                    new IconSearchOption('all', '', 'fa-minus fa-stack-1x', 'fa-minus', 'global.literals.all'),
                    new IconSearchOption('NEW','NEW', 'fa-circle-o text-blue fa-lg', 'fa-circle-o text-blue fa-lg', 'global.documentState.NEW'),
                    new IconSearchOption('NEW_AND_ATTACHMENT','NEW_AND_ATTACHMENT', 'fa-adjust text-blue fa-lg', 'fa-adjust text-blue fa-lg', 'global.documentState.NEW_AND_ATTACHMENT'),
                    new IconSearchOption('PENDING_SIGN','PENDING_SIGN', 'fa-circle text-blue fa-lg', 'fa-circle text-blue fa-lg', 'global.documentState.PENDING_SIGN'),
                    new IconSearchOption('SIGNED','SIGNED', 'fa-circle text-success fa-lg', 'fa-circle text-success fa-lg', 'global.documentState.SIGNED'),
                    new IconSearchOption('REJECTED','REJECTED', 'fa-circle text-danger fa-lg', 'fa-circle text-danger fa-lg', 'global.documentState.REJECTED'),
                    new IconSearchOption('COMPLETE','COMPLETE', 'fa-dot-circle-o text-success fa-lg', 'fa-dot-circle-o text-success fa-lg', 'global.documentState.COMPLETE')
                ], 'tableDocs');

            var actionsTitle = function () {
                return '<div class="column-actions">' + $filter('translate')('global.literals.actions') + '</div>';
            }

            var actionsRender = function (data, type, full, meta) {
            	return '<annexa-document-actions-button document="tableObjects[' + full.id + '].document" list="true" open-in-new-window="true" module="SEC" sec-document="tableObjects[' + full.id + ']" object-to-call="objectToCall"></annexa-document-actions-button>';
            }

            $scope.$on('SendToSignModalFinished', function(event, args) {
                vm.tableDefinition.reloadInternalData(false, true);
            });

            $scope.$on('documentActionButtonExecuted', function(event ,args) {
                vm.tableDefinition.reloadInternalData(false,true);
            });

            $scope.$on('sessionGenerateAct', function(event, args){
            	vm.tableDefinition.reloadInternalData(false,true);
            });
            
            $scope.$on('sessionGenerateAgreements', function(event, args){
            	vm.tableDefinition.reloadInternalData(false,true);
            });
            
            $scope.$on('sessionGenerateCertificates', function(event, args){
            	vm.tableDefinition.reloadInternalData(false,true);
            });
            $scope.$on('sessionGenerateConvene', function(event, args) {
            	vm.tableDefinition.reloadInternalData(false,true);
            });
            $scope.$on('sessionUpdatedConvene', function(event, args) {
            	vm.tableDefinition.reloadInternalData(false,true);
            });
            $scope.$on('allowGenerateFromContentDocumentTypeExecutedSec', function(event, args){
            	if(args && args.sessionId && vm.sessionComponent.session && vm.sessionComponent.session.id && args.sessionId == vm.sessionComponent.session.id && args.data && args.data.id){
            		var newContent = [];
            		newContent.push(args.data.id);
                	vm.saveSearch(newContent);
            	}
            });
            
            $scope.$on('documentActionButtonExecuted', function(event, args){
            	if (args.button) {
                    switch (args.button) {
                        case 'deleteSecDocument':
                        	vm.tableDefinition.reloadInternalData(false,true);
                            break;
                    }
                }
            });
            
            vm.saveSearch = function(newDocuments) {
            	if(newDocuments && newDocuments.length > 0){
            		
            		var modal = angular.copy(SecModals.selectProposalToAddDocument);
		            modal.alerts = [];
		            modal.annexaFormly.model = {};
		            modal.annexaFormly.model.modal_body = {};
		            modal.annexaFormly.options = {};
		            modal.annexaFormly.options.formState = {readOnly: false};
		            if(vm.sessionComponent.session.executedOrder && vm.sessionComponent.session.executedOrder.length > 0){
		            	modal.annexaFormly.model.modal_body.proposals = $linq(vm.sessionComponent.session.executedOrder).where("x => x.proposal && x.proposal.id").select("x => x.proposal").distinct("(x, y) => x.id == y.id").toArray();	
		            }else if(vm.sessionComponent.session.proposedOrder && vm.sessionComponent.session.proposedOrder.length > 0){
		            	modal.annexaFormly.model.modal_body.proposals = $linq(vm.sessionComponent.session.proposedOrder).where("x => x.proposal && x.proposal.id").select("x => x.proposal").distinct("(x, y) => x.id == y.id").toArray();
		            }else{
		            	modal.annexaFormly.model.modal_body.proposals = [];
		            }
		            
		            if(modal.annexaFormly.model.modal_body.proposals && modal.annexaFormly.model.modal_body.proposals.length > 0){
			            var submitProposalToAddDocument = function () {
			            	var proposal = -1;
		            		if(this.annexaFormly.model.modal_body.proposalAdded && this.annexaFormly.model.modal_body.proposalAdded > 0){
			            		proposal = this.annexaFormly.model.modal_body.proposalAdded;
			            	}
			                $http({
			                    url: '/api/sec/session/'+vm.sessionComponent.session.id+'/documents/'+CommonService.getParameterList(newDocuments)+'/'+proposal,
			                    method: 'POST'
			                }).then(function (data) {
			                    modal.close();
			                	vm.tableDefinition.reloadInternalData(false, true);
			                }).catch(function (error) {
			                    var a = 0;
			                });
			            };
			
			            AnnexaFormlyFactory.showModal("modalProposalToAddDocument", modal, submitProposalToAddDocument, false);
		            }else{
		                $http({
		                    url: '/api/sec/session/'+vm.sessionComponent.session.id+'/documents/'+CommonService.getParameterList(newDocuments)+'/-1',
		                    method: 'POST'
		                }).then(function (data) {
		                    vm.tableDefinition.reloadInternalData(false, true);
		                }).catch(function (error) {
		                    var a = 0;
		                });
		        	
		            }
            	}
            }
            
			vm.fromTemplate = function(){
                DialogsFactory.confirm('global.sec.literals.confirmGenerateDocumentTitle', 'global.sec.literals.confirmGenerateDocumentBody').then(function (dataAux) {
                	SecFactory.createDocumentModal('OTHER', null, 'session', vm.sessionComponent.session, true)
                    .then(function(data) {
                        var selfData = data;
						$http({
                            url: './api/sec/session/' + vm.sessionComponent.session.id + '/externalDocument',
                            method: 'POST', 
                            data: JSOG.encode(selfData.request)
                        }).then(function(data) {
                        	selfData.modal.close();
                            vm.tableDefinition.reloadInternalData(false, true);
                        }).catch(function (error) {
                        	selfData.modal.close();
                        	if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
								var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.data.message)?'<br/><br/>'+error.data.message:'');
								DialogsFactory.error(msg, $filter('translate')('DIALOGS_ERROR_MSG'));
							}else{
			                	DialogsFactory.error($filter('translate')('global.sec.errors.cantGenerateDocument'));
                        	}
                        });
                    }).catch(function(error) {
                        var a = 0;
                    })
                }).catch(function (data) {
                	//Empty;
	            });
			}
            
            this.$onInit = function () {
            	vm.searchedValue = undefined;
            	vm.placeholder = 'global.literals.placeholser_search_documents';
            	vm.processSearch = function (object) {
            		return object.name;
                }
            	vm.searchFunc = function(val) {
                    var added = [];
                    if(vm.tableDefinition && vm.tableDefinition.list && vm.tableDefinition.list.length > 0){
                    	added = $linq(vm.tableDefinition.list).select("x => x.document.id").toArray();
                    }
                    var filter = { name: val.val };
                    var additionalFilter = { exceptState: 'NEW', documentBoxNormalSearch: true };
                    additionalFilter.loggedUser = $rootScope.LoggedUser.id;
                    additionalFilter.added = added;
                    additionalFilter.langColumn = Language.getActiveColumn();

                    return RestService.filterData('doc', 'Document', filter, additionalFilter, 0, 10, '', 3, 'box')
                        .then(function (data) {
                            var response = [];
                            _.forEach(data.data.content, function(value, key) {
                                response.push({
                                    id: value.id,
                                    object: JSOG.decode(value),
                                    value: vm.processSearch(JSOG.decode(value))
                                });
                            });
                            response = new SortedArray(response, 'value').sort();
                            return response;
                        });
                }
            	vm.typeaheadSelect = function (item) {
                    if (item && item.value.indexOf('</i>') > 0) {
                        item.value = item.value.substring(item.value.indexOf('</i>') + 4, item.value.length);
                    }
                }
            	vm.addText = 'global.literals.add';
            	vm.addedSelect = 'x => x.id';
            	vm.searchAdd = function () {
                    if(vm.searchedValue && vm.searchedValue.id && vm.searchedValue.id != -1) {
                    	vm.completeAdd(this);
                    }
                }
        		
            	vm.newFunc = function() {
                    var preCloseModal = function(document) {
                        vm.searchedValue = {
                            id: document.id, value: document.name,
                            object: {
                                id: document.id,
                                docStatus: document.docStatus,
                                name: document.name,
                                profiles: document.profiles,
                                code: document.code
                            }
                        };
                        vm.completeAdd();
                    }

                    DccumentsFactory.openNewDocument(undefined, preCloseModal, 'SEC',undefined,undefined, ((vm.sessionComponent && vm.sessionComponent.session && vm.sessionComponent.session.archiveClassificationAux)?vm.sessionComponent.session.archiveClassificationAux:undefined));
                }
            	
            	var preCloseModalFunction = function() {
            		 vm.searchedValue = {
                             object: []
                         };
                    _.forEach(this.wizard.steps[this.wizard.steps.length - 1].annexaFormly.fields[0].data.documents, function(item) {
                        if(item.procState == 'complete') {
                            vm.searchedValue.object.push({
                                id: item.docData.id,
                                docStatus: item.docData.docStatus,
                                name: item.docData.name,
                                profiles: item.docData.profiles,
                                code: item.docData.code
                            });
                        }
                    });
                    vm.completeAdd();
                }
            	
            	vm.newMultipleFunc = function() {
                    DccumentsFactory.openMasiveDocumentModalFolder(preCloseModalFunction, self, ((vm.sessionComponent && vm.sessionComponent.session && vm.sessionComponent.session.archiveClassificationAux)?vm.sessionComponent.session.archiveClassificationAux:undefined));
                }

                if($rootScope.app.configuration.load_documents_from_scan_folder.value) {
                    vm.newMultipleScannerFunc = function () {
                        DccumentsFactory.openMasiveDocumentModalScan(preCloseModalFunction, self, ((vm.sessionComponent && vm.sessionComponent.session && vm.sessionComponent.session.archiveClassificationAux)?vm.sessionComponent.session.archiveClassificationAux:undefined));
                    }
                }
            	
                vm.advancedSearch = function(){
                	var showStyledDocumentTypeAcronym = $rootScope.app.configuration.show_styled_document_type_acronym.value;
                	var added = [];
                    if(vm.tableDefinition && vm.tableDefinition.list && vm.tableDefinition.list.length > 0){
                    	added = $linq(vm.tableDefinition.list).select("x => x.document.id").toArray();
                    }
                    
                    var docStatusRender = function(data, type, full, meta) {
                        var content = '';

                        if(DccumentsFactory.statuses && DccumentsFactory.statuses.length > 0) {
                            var status = $linq(DccumentsFactory.statuses).singleOrDefault(undefined, "x => x.id == '" + data + "'");

                            if(status) {
                                content += '<div class="text-center">';
                                content += '    <span class="fa-stack" title="' + $filter('translate')('global.documentState.' + status.name) + '">';
                                content += '        <i class="fa fa-' + status.icon + ' text-' + status.style + ' text-lg fa-stack-1x"></i>';
                                content += '    </span>';
                                content += '</div>';
                            }
                        }

                        return content;
                    }
                    
                    var advancedSearchModal = {
                        title: 'global.literals.advanced_search_documents',
                        size: 'modal-lg',
                        filter: [
                            { id: 'code', type: 'text', order: 0, label: 'global.literals.code' },
                            { id: 'name', type: 'text', order: 1, label: 'global.literals.title' },
                            { id: 'csv', type: 'text', order: 2, label: 'global.documents.new.csv' },
                            { id: 'internalDoc', type: 'checkbox', order: 3, label: 'global.documents.internalDocument', callAux: true, model: true },
                            { id: 'template', type: 'select', order: 0, label: 'global.literals.template', dataType: 'LOCAL', data: $linq(GlobalDataFactory.templates).orderBy("x => x." + vm.languageColumn).toArray(), addAll: true, nameProperty: 'description', userChoosen: true },
                            { id: 'section', type: 'select', order: 1, label: 'global.literals.section', dataType: 'LOCAL', data: $linq(GlobalDataFactory.sections).orderBy("x => x." + vm.languageColumn).toArray(), addAll: true, nameProperty: vm.languageColumn, userChoosen: true },
                            { id: 'type', type: 'select', order: 2, label: 'global.literals.type', dataType: 'LOCAL', data: $linq(GlobalDataFactory.documentTypes).where("x => x.isPublic").toArray(), addAll: true, nameProperty: vm.languageColumn, userChoosen: true },
                            { id: 'origin', type: 'select', order: 3, label: 'global.literals.module', dataType: 'LOCAL', data: GlobalDataFactory.docModules, addAll: true, nameProperty: vm.languageColumn, callAux: true, userChoosen: true },
                            { id: 'createUser', type: 'text', order: 4, label: 'global.literals.user', callAux: true, userChoosen: true },
                            { id: 'meta', type: 'text', order: 5, label: 'global.literals.meta', callAux: true, userChoosen: true },
                            { id: 'modifyDdate', type: 'dateRange', order: 6, label: 'global.literals.modified', callAux: true, userChoosen: true },
                            { id: 'dossierNumber', type: 'text', order: 7, label: 'global.literals.numExp', callAux: true, userChoosen: true },
                            { id: 'docStatus', type: 'select', order: 8, label: 'global.literals.status', callAux: true, dataType: 'LOCAL', data: $linq(GlobalDataFactory.documentStatuses).orderBy("x => x.orderStatus").toArray(), addAll: true, nameProperty: 'nameLiteral', userChoosen: true }
                        ],
                        tableDefinition: {
                            id: 'tableAdvancedSearchDocumentsPopupOrderPoints',
                            origin: 'doc',
                            objectType: 'Document',
                            sortName: 'modifyDate',
                            sort: [[5, 'desc']],
                            columns: [
                            	{ id: 'docStatus', width: '10%', title: $filter('translate')('global.literals.status'), render: docStatusRender, sortable: false },
                                { id: 'type', width: '10%', column: showStyledDocumentTypeAcronym ? new IconColumn($filter, 'global.literals.type', 'acronym', vm.languageColumn) : new ObjectTextColumn($filter, 'global.literals.type', 'acronym') , sortable: false },
                                { id: 'id', width: '10%', column: new UIRouterStateColumn($filter, 'global.literals.code', 'annexa.doc.documents.view({code: \'[data]\'})', undefined, 'var code = full.id; if(full.code) { code = full.code.substr((full.code.indexOf(full.id) - 2), full.id.toString().length + 2); } code;') },
                                { id: 'id', column: new UIRouterStateColumnNoLink($filter, 'global.literals.title', 'annexa.doc.documents.view({code: \'[data]\'})', 'name')  },
                                { id: 'createUser', width: '20%', column: new UserColumn($filter, 'global.literals.user') },
                                { id: 'modifyDate', width: '20%', column: new DateTimeColumn($filter, 'global.literals.modifyDate') }
                            ], 
                            noLoading: true,
                            skipFirstSearch: true,
                            callAuxToAdd: { added: added }
                        },
                        multiple: true
                    }

                    AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASDocumentsPopupSessionDocuments', advancedSearchModal);
                }
                
                $scope.$on('annexaAdvancedSearchModalAdd', function(event, args){
                	if(args && args.id && args.id == 'modalASDocumentsPopupSessionDocuments' && args.selected && args.selected.length > 0){
            			if(!vm.searchedValue){
            				vm.searchedValue = {};
            			}
            			vm.searchedValue.object = args.selected;
                		vm.completeAdd();
                        args.modal.close();
                	}
                });
                
                vm.completeAdd = function() {
                	var newContent = [];
                	if(vm.searchedValue && vm.searchedValue.object && Array.isArray(vm.searchedValue.object)){
                		_.forEach(vm.searchedValue.object, function(obj){
                			newContent.push(obj.id);
                		});
                	}else{
                		if(vm.searchedValue && vm.searchedValue.object){
                			newContent.push(vm.searchedValue.object.id);
                		}
                	}
            		vm.searchedValue = { id: -1, value: '' };
                    vm.saveSearch(newContent);
                }
            	
            	vm.tableDefinition = {
                    id: 'tableDocs',
                    origin: 'sec',
                    objectType: 'SessionDocument',
                    sortName: 'createdDate',
                    sort: [[6, 'desc']],
                    filterCall: getFilterCall(),
                    filterCallAux: getFilterCallAux(),
                    filterCallFunc: getFilterCall,
                    filterCallAuxFunc: getFilterCallAux,
                    columns: [
                        { id: 'document.publicDocument', width: '40px', column: new BooleanColumn($filter, 'global.documentState.public', 'public'), sortable: false },
                        { id: 'document.docStatus', width: '40px', title: $filter('translate')('global.literals.state'), render: documentTypeColumn.getRender },
                        { id: 'document.type', width: '40px', column: showStyledDocumentTypeAcronym ? new IconColumn($filter, 'global.literals.type', 'acronym', vm.languageColumn) : new ObjectTextColumn($filter, 'global.literals.type', 'acronym') , sortable: false },
                        { id: 'document.id', width: '250px', column: new UIRouterStateColumn($filter, 'global.literals.code', 'annexa.doc.documents.view({code: \'[data]\'})', undefined, 'var code = full.document.id; if(full.document.code) { code = full.document.code.substr((full.document.code.indexOf(full.document.id) - 2), full.document.id.toString().length + 2); } code;', true) },
                        { id: 'document.id', width: '250px', column: new UIRouterStateColumn($filter, 'global.literals.title', 'annexa.doc.documents.view({code: \'[data]\'})', undefined, 'full.document.name;', true) },
                        { id: 'document.createdDate', column: new DateTimeColumn($filter, 'global.literals.creation_date') },
                        { id: 'actions', columnName: 'document.id', width: '120px', className: 'text-center', title: actionsTitle(), render: actionsRender, sortable: false }
                    ],
                    containerScope: $scope,
                    useTableObjects: true,
                    multipleActions: true,
                    functionMultipleActionsColumnShow: function(item) {
                    	return true;		
                    },
                    functionMultipleActionsReload: function(items) {
                        var options = [];
                        var documents = [];
                        _.forEach(items, function(item) {
                            if(item.selected) {
                                var evaluatedOptions = AnnexaDocumentActionsButtonFactory.getShowOptions(item.document, undefined, undefined, vm.sessionComponent.isNotFinalizedSession, 'SEC');
                                if (options.length == 0) {
                                    options = evaluatedOptions;
                                } else {
                                    options = $linq(options).intersect(evaluatedOptions, "(x, y) => x == y").toArray();
                                }
                                documents.push(item.document);
                            }
                        });

                        if(options.length != 0) {
                            vm.tableDefinition.multipleActionsFunction(AnnexaDocumentActionsButtonFactory.getMultipleActionsButton(options, undefined, 'SEC', documents));
                        } else {
                            vm.tableDefinition.multipleActionsFunction();
                        }

                    },
                    parentObject: vm.sessionComponent.session,
                    parentObjectAttribute: 'archiveClassificationAux'
                };
            }
        }]
    });