/**
 * Created by osirvent on 16/05/2017.
 */
angular
    .module('annexaApp')
    .component('annexaSessionPoints', {
        templateUrl: './components/sec/annexa-session-points/annexa-session-points.html',
        controller: ['SecFactory', 'RestService', 'Language', function(SecFactory, RestService, Language) {
            var vm = this;

            vm.proposals = [];
            vm.pointTitle = "";
            vm.pointObservations = "";
            vm.documents = [];
			vm.selectAllModel = false;
            vm.languageColumn = Language.getActiveColumn();
            vm.showTable = true;

            var fixProposals = function (items) {
                vm.proposals = [];
                vm.pointTitle = "";
                vm.pointObservations = "";
                vm.showTable = true;
				vm.selectAllModel = false;
                _.forEach(items, function (value) {
                    var prop = {
                        selected: false,
                        createdUser: {
                            name: value.senderUser.name,
                            surename1: value.senderUser.surename1,
                            surename2: value.senderUser.surename2
                        },
                        proposalNumber: value.proposalNumber,
                        createdDate: value.sendDate,
                        extract: value.extract,
                        id: value.id,
                        documents:[],
                        documentsProp: value.documents,
                        votationType:'ORDINARY',
                        requiredVoteState:'APPROVED_SIMPLE_MAJORITY',
                        createdTransaction: {
                        	id:value.createdTransaction.id,
                        	dossier:{
                        		id:value.createdTransaction.dossier.id,
                        		subject:value.createdTransaction.dossier.subject,
                        		dossierNumber:value.createdTransaction.dossier.dossierNumber,
                        		dossierStatus:value.createdTransaction.dossier.dossierStatus
                        	}
                        },
                        profile: value.profile
                    };

                    vm.proposals.push(prop);
                });
            }

            var fixTitleAndObservations = function () {
                vm.proposals = [];
                vm.documents = [];
                vm.pointTitle = "";
                vm.pointObservations = "";
                vm.votationType = "ORDINARY";
                vm.requiredVoteState = "APPROVED_SIMPLE_MAJORITY";
                vm.showTable = false;
				vm.selectAllModel = false;

            }

			vm.selectAllFunction = function(){
				if(vm.proposals && vm.proposals.length > 0){
					_.forEach(vm.proposals, function(proposal){
						if(vm.selectAllModel){
							proposal.selected = true;
						}else{
							proposal.selected = false;
						}
					});					
				}
			}
			
            this.$onChanges = function (changes) {
                if(changes.type) {
                    vm.proposals = [];

                    var pointType = changes.type.currentValue;
                    
                    if(_.contains(['VOTE'], pointType.type)){
                    	 vm.showVotes = true;
                    }else if(_.contains(['PROPOSAL', 'OPINION'], pointType.type) && pointType.vote){
                    	vm.showVotes = true;
                    }else{
                    	 vm.showVotes = false;
                    }
                    
                    
                    if(!_.contains(['VOTE', 'NOVOTE', 'GROUP'], pointType.type)) {
                        SecFactory.getSessionProposalsByType(SecFactory.session.id, pointType.type)
                            .then(function(data) {
                                fixProposals(data);
                            }).catch(function(error) {
                                vm.proposals = [];
								vm.selectAllModel = false;
                        })
                    } else {
                        fixTitleAndObservations();
                    }
                }
            }

            vm.selectProposal = function($event, index) {
                var checkbox = $event.target;
                vm.proposals[index].selected = checkbox.checked;

				var count = $linq().count();
				if(count == vm.proposals.length){
					vm.selectAllModel = true;
				}else{
					vm.selectAllModel = false;
				}
            }
            vm.votationTypes = SecFactory.votationTypes;
            vm.requiredVoteStates = SecFactory.requiredVoteStates;
            vm.showVotes = false;
        }],
        bindings: {
            type: '<',
            proposals: '=?',
            pointTitle: '=?',
            pointObservations: '=?',
            documents: '=?',
            canEdit: '=?',
            votationType: '=?',
        	requiredVoteState:'=?'
        }
    })