angular
    .module('annexaApp')
    .component('annexaBoxObjectSessionTransfers',{
        templateUrl: './components/sec/annexa-box-object-session-transfers/annexa-box-object-session-transfers.html',
        require: {
            sessionComponent: '^^annexaBoxObjectSession'
        },
        controller: ['SecFactory', '$filter', '$rootScope', 'ErrorFactory', '$state', '$scope', 'RestService', 'HelperService', 'AnnexaPermissionsFactory', 'GlobalDataFactory', 'Language', 'AnnexaFormlyFactory', 'CommonService', 'DialogsFactory', 'HeaderService','NotificationFactory', 
        	function (SecFactory, $filter, $rootScope, ErrorFactory, $state, $scope, RestService, HelperService, AnnexaPermissionsFactory, GlobalDataFactory, Language, AnnexaFormlyFactory, CommonService, DialogsFactory, HeaderService, NotificationFactory) {
            	var vm = this;

                vm.languageColumn = Language.getActiveColumn();
                vm.notificationStatus = angular.copy(NotificationFactory.notificationGlobalStatus);
                vm.notificationStatus.unshift({id: 'NO_SEND', name: $filter('translate')('NO_SEND'), class: 'text-grey', icon: 'circle', style: 'text-grey'});
                
                vm.dossierTransactionStatus = [];
                vm.dossierTransactionStatus.push({id: 'NO_CREATED', name: $filter('translate')('NO_CREATED'), class: 'text-grey', icon: 'circle', style: 'text-grey'});
                vm.dossierTransactionStatus.push({id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS'), class: 'text-warn', icon: 'circle', style: 'text-warn'});
                vm.dossierTransactionStatus.push({id: 'FINISHED', name: $filter('translate')('FINISHED'), class: 'text-success', icon: 'circle', style: 'text-success'});
                var tramStatusRender = function(data, type, full, meta) {
                	
                	
                	
                    var content = '';
                    var classStatus = '';
                    var status = '';
                    if(full.notificationTransaction){
                    	if(full.notificationTransaction.endDate) {
	                        classStatus = 'success';
	                        status = 'FINISHED';
                    	}else{
	                        classStatus = 'warn';
	                        status = 'IN_PROGRESS';
                    	}
	                }else{
	                	classStatus = '';
	                	status = 'NO_CREATED';
                    }
                    content += '<strong>';
                    content += '    <span class="inline label rounded ' + classStatus + '">';
                    content += 		       $filter('translate')(status);
                    content += '    </span>';
                    content += '</strong>';
                   
                    return content;
                }
                
               
                var tramDateRender = function(data, type, full, meta) {
                    var content = '';
                    if(full.notificationTransaction){
	                    if(full.notificationTransaction.endDate) {
	                    	content += $filter('date')(new Date(full.notificationTransaction.endDate), 'dd/MM/yyyy - HH:mm');
	                    }else{
	                    	content += $filter('translate')('global.sec.literals.noTransfered');
	                    }
                    }else{
                        content += $filter('translate')('global.sec.literals.noTransfered');
                    }
                    return content;
                }
                
                var notStatusRender = function(data, type, full, meta) {
                    var content = '';
                    if(full.notificationTransaction && full.notificationTransaction.dossier && full.notificationTransaction.dossier.notifications && full.notificationTransaction.dossier.notifications.length > 0){
	                    var notifications = $linq(full.notificationTransaction.dossier.notifications).where(function(x){
	                    	if(x.documents && x.documents.length > 0){
	                    		var haveDocument = false;
	                    		_.forEach(x.documents, function(doc){
	                    			if(_.contains(vm.sessionComponent.idsCertificatesDocument,doc.id)){
	                    				haveDocument = true;
	                    			}
	                    		});
	                    		return haveDocument;
	                    	}else{
	                    		return false;
	                    	}
	                    }).orderBy("x => x.createdDate").toArray();
	                    if(vm.notificationStatus && vm.notificationStatus.length > 0 && notifications && notifications.length > 0) {
	                    	var state = $linq(vm.notificationStatus).singleOrDefault(undefined, "x => x.id == '" + notifications[0].globalStatus + "'");
	                    	content = '<a href="" ng-click="$ctrl.showNotificationModal('+notifications[0].id+')" class="text-info linkNoStyle"> <i class="material-icons '+state.style+'" title="'+$filter('translate')(state.name)+'">notifications</i></a>';
	                    }else{
	                    	var state = $linq(vm.notificationStatus).singleOrDefault(undefined, "x => x.id == 'NO_SEND'");
	                        if(state) {
	                            content += '<i class="material-icons text-danger" title="'+$filter('translate')(state.name)+'">notifications_off</i>';
	                        }
	                    }
                    }else{
                        var state = $linq(vm.notificationStatus).singleOrDefault(undefined, "x => x.id == 'NO_SEND'");
                        if(state) {
                            content += '<i class="material-icons text-danger" title="'+$filter('translate')(state.name)+'">notifications_off</i>';
                        }
                    }
                    return content;
                }
                
                vm.showNotificationModal = function (notificationId) {
                    RestService.findOne('Notification', notificationId).then(function (data) {
                        var decodedData = JSOG.decode(data.data);
                    	var notificationViewModal = NotificationFactory.notificationViewModal(decodedData);

                        AnnexaFormlyFactory.showAnnexaFormModal('modalViewNotification', notificationViewModal);    
                    }).catch(function (error) {
                    	DialogsFactory.error('global.sec.literals.canNotOpenNotification')
                    });
                    
                };
                
                var dossierRender = function(data, type, full, meta) {
                	var content = '';
                    if(data && data.dossier && data.dossier.dossierNumber) {
                    	var canView = chkCanView(data.dossier);
                    	var canProcess = chkCanProcess(data.dossier);
                    	if(canView || canProcess) {
                    		var dossierTransactionId = getDossierTransactionId(data.dossier);
                        	content += '<button class="text-primary _400 btn-link p-a-0" type="button" ng-click="$ctrl.redirect(' + data.dossier.id + ', ' + data.dossier.bigDossier + ', ' + canProcess + ', ' + dossierTransactionId + ')" >' + data.dossier.dossierNumber + '</button>';
                        } else {
                        	content += '<span>' + data.dossier.dossierNumber + '</span>';
                        }
                    }
                    
                    return content;
                }
                
                var chkCanProcess = function (dossier) {
                    var haveProcessProfile = false;

                    if(AnnexaPermissionsFactory.havePermission('process_dossier')) {
                        var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
                        var dossierProcessProfiles = [];
                        if(dossier && dossier.dossierTransactions) {
                            _.forEach(dossier.dossierTransactions, function (dt) {
                                if(dt.processProfiles) {
                                    _.forEach(dt.processProfiles, function (item) {
                                        dossierProcessProfiles.push(item.profile.id);
                                    })
                                }
                            });
                        }
                        var intersect = $linq(userProfiles).intersect(dossierProcessProfiles, "(x,y) => x == y").toArray();

                        haveProcessProfile = intersect.length > 0;

                    }

                    return haveProcessProfile;
                }

                var chkCanView = function (dossier) {
                    var haveViewProfile = false;

                    if(AnnexaPermissionsFactory.havePermission('view_dossier')) {
                        var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
                        if(dossier && dossier.procedure && dossier.procedure.procedureViewProfiles) {
                            var dossierViewProfiles = $linq(dossier.procedure.procedureViewProfiles).select("x => x.profile.id").toArray();

                            var intersect = $linq(userProfiles).intersect(dossierViewProfiles, "(x,y) => x == y").toArray();

                            haveViewProfile = intersect.length > 0;
                        }
                    }

                    return haveViewProfile;
                }
                
                var getDossierTransactionId = function (dossier) {
                    var dossierTransactionId;
                    if(AnnexaPermissionsFactory.havePermission('process_dossier')) {
                        var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
                        if(dossier && dossier.dossierTransactions) {
                            _.forEach(dossier.dossierTransactions, function (dt) {
                                if (dt.actual && dt.processProfiles) {
                                    var dossierProcessProfiles = [];
                                    _.forEach(dt.processProfiles, function (item) {
                                        dossierProcessProfiles.push(item.profile.id);
                                    })
                                    var intersect = $linq(userProfiles).intersect(dossierProcessProfiles, "(x,y) => x == y").toArray();
                                    if (intersect.length > 0) {
                                        dossierTransaction = dt.id;
                                    }
                                }
                            });
                        }
                    }
                    return dossierTransactionId;
                }
                
                vm.redirect = function (dossierId, bigDossier, canProcess, dossierTransactionId) {
                    if(canProcess){
                        if(dossierTransactionId) {
                        	if(bigDossier){
    	        	        	DialogsFactory.confirm('global.literals.bigDossierMessage', 'global.literals.bigDossierMessageConfirm')
    	        	            .then(function (data) {
    	                    		window.open($state.href('annexa.tram.pending.viewdossier', {dossier: dossierId, dossierTransaction: dossierTransactionId}), '_blank');	            
    	        	           }).catch(function (data) {
    	        		               //Empty
    	        		       });
                        	}else{
                        		window.open($state.href('annexa.tram.pending.viewdossier', {dossier: dossierId, dossierTransaction: dossierTransactionId}), '_blank');
                        	}
                        }else{
                        	if(bigDossier){
    	        	        	DialogsFactory.confirm('global.literals.bigDossierMessage', 'global.literals.bigDossierMessageConfirm')
    	        	            .then(function (data) {
    	        	            	window.open($state.href('annexa.tram.view.viewdossier', { dossier: dossierId, dossierTransaction: -1 }), '_blank');	            
    	        	           }).catch(function (data) {
    	        		               //Empty
    	        		       });
                        	}else{
                        		window.open($state.href('annexa.tram.view.viewdossier', { dossier: dossierId, dossierTransaction: -1 }), '_blank');
                        	}
                        }
                    }else{
                    	if(bigDossier){
            	        	DialogsFactory.confirm('global.literals.bigDossierMessage', 'global.literals.bigDossierMessageConfirm')
            	            .then(function (data) {
            	            	window.open($state.href('annexa.tram.view.viewdossier', { dossier: dossierId, dossierTransaction: -1 }), '_blank');	            
            	           }).catch(function (data) {
            		               //Empty
            		       });
                    	}else{
                    		window.open($state.href('annexa.tram.view.viewdossier', { dossier: dossierId, dossierTransaction: -1 }), '_blank');
                    	}
                    }
                }
                
                var getFilterCall = function() {
                    var filterCall = {};

                    return filterCall;
                }

                var getFilterCallAux = function () {
                    return { 
                    	session: vm.sessionComponent.session.id,
                    	resolutionType: 'AGREEMENT',
                    	transfers: true
                    };
                }

                this.$onInit = function () {
                    vm.tableDefinition = {
                        id: 'tableTransfer',
                        origin: 'sec',
                        objectType: 'Proposal',
                        sortName: 'createdDate',
                        sort: [[1, 'asc']],
                        filterCall: getFilterCall(),
                        filterCallAux: getFilterCallAux(),
                        filterCallFunc: getFilterCall,
                        filterCallAuxFunc: getFilterCallAux,
                        columns: [
                        	{ id: 'id', width: '1%', title: $filter('translate')('global.literals.state'), render: tramStatusRender, sortable: false },
                        	{ id: 'createdDate', title: $filter('translate')('global.sec.literals.transferDate'), render: tramDateRender, sortable: false },
                            { id: 'proposalNumber', column: new TextColumn($filter, 'global.sec.literals.proposalNumber') },
                            { id: 'extract', column:  new TextColumn($filter, 'global.literals.title') },
                            { id: 'createdTransaction', title: $filter('translate')('global.literals.dossier'), render: dossierRender },
                            { id: 'id', title: $filter('translate')('global.sec.literals.notificationStatus'), render: notStatusRender, sortable: false, className: 'notificationStatus'}
                            
                        ],
                        containerScope: $scope,
                        useTableObjects: true,
                        multipleActions: false,
						callOrigin: 'transfer'
                    };
                }
        }]
    })
