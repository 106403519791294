angular
    .module('annexaApp')
    .component('annexaSessionVote', {
        templateUrl: './components/sec/annexa-session-vote/annexa-session-vote.html',
        controller: ['$filter', 'Language', 'DialogsFactory', function ($filter, Language, DialogsFactory) {
            var vm = this;
            vm.secret = false;
            this.$onInit = function () {
            	if(vm.sessionOrder && vm.sessionOrder.votes) {
            		vm.votes = $linq(vm.sessionOrder.votes).where("x => x.type == '" + vm.type + "'").toArray();
            	}
                
                if(vm.canEdit == undefined || vm.canEdit == null){
            		vm.canEdit = true;
            	}
                
                if(!vm.votationType){
                	vm.votationType == 'ORDINARY'
                }
            }
            
            vm.languageColumn = Language.getActiveColumn();

            vm.submitVoteAll = function (vote) {
                _.forEach(vm.votes, function(item, index) {
                    vm.submitVote(vote, index);
                });
            }

            vm.submitVote = function (vote, index) {
                vm.votes[index].vote = vote;
            }
        }],
        bindings: {
            sessionOrder: '=',
            maxVotes: '=',
            type: '@',
            votationType: '=?',
            canEdit: '=?'
        }
    })