angular
    .module('annexaApp')
    .component('annexaBoxObjectSessionOrdersDocuments', {
        templateUrl: './components/sec/annexa-box-object-session-orders-documents/annexa-box-object-session-orders-documents.html',
        controller:['$scope', '$element', '$attrs', '$rootScope', '$q', '$window', 'Language', 'RestService', 'AnnexaFormlyFactory', 'GlobalDataFactory', '$filter', 'DccumentsFactory', 
        function ($scope, $element, $attrs, $rootScope, $q, $window, Language, RestService, AnnexaFormlyFactory, GlobalDataFactory, $filter, DccumentsFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();

            vm.searchFunc = function(val) {
                var added = $linq(vm.documents).select("x => x.document.id").toArray();

                var filter = { name: val.val };

                var additionalFilter = {};
                additionalFilter.loggedUser = $rootScope.LoggedUser.id;
                additionalFilter.added = added;
                additionalFilter.langColumn = vm.languageColumn;
                additionalFilter.secSearch = true;
                return RestService.filterData('doc', 'Document', filter, additionalFilter, 0, 10, '', 3, 'box')
                    .then(function (data) {
                        var response = [];

                        _.forEach(data.data.content, function(value, key) {
                            response.push({
                                id: value.id,
                                object: JSOG.decode(value),
                                value: JSOG.decode(value).name
                            });
                        });

                        response = new SortedArray(response, 'value').sort();

                        return response;
                    });

            }
            
            vm.typeaheadSelect = function(item){
            	if (item && item.value.indexOf('</i>') > 0) {
                    item.value = item.value.substring(item.value.indexOf('</i>') + 4, item.value.length);
                }
            }
            
            vm.searchAdd = function() {
            	if(vm.searchedValue && vm.searchedValue.object){
            		vm.documents.push({document:vm.searchedValue.object});
	            	if(vm.saveDocuments){
	            		vm.saveDocuments(vm.documents, undefined, vm.model);
	            	}
            	}
            	vm.searchedValue = undefined;
            }

            vm.advancedSearch = function(){
            	var showStyledDocumentTypeAcronym = $rootScope.app.configuration.show_styled_document_type_acronym.value;
                
            	 var docStatusRender = function(data, type, full, meta) {
                     var content = '';

                     if(DccumentsFactory.statuses && DccumentsFactory.statuses.length > 0) {
                         var status = $linq(DccumentsFactory.statuses).singleOrDefault(undefined, "x => x.id == '" + data + "'");

                         if(status) {
                             content += '<div class="text-center">';
                             content += '    <span class="fa-stack" title="' + $filter('translate')('global.documentState.' + status.name) + '">';
                             content += '        <i class="fa fa-' + status.icon + ' text-' + status.style + ' text-lg fa-stack-1x"></i>';
                             content += '    </span>';
                             content += '</div>';
                         }
                     }

                     return content;
                 }
            	 
                var advancedSearchModal = {
                    title: 'global.literals.advanced_search_documents',
                    size: 'modal-lg',
                    filter: [
                        { id: 'code', type: 'text', order: 0, label: 'global.literals.code' },
                        { id: 'name', type: 'text', order: 1, label: 'global.literals.title' },
                        { id: 'csv', type: 'text', order: 2, label: 'global.documents.new.csv' },
                        { id: 'internalDoc', type: 'checkbox', order: 3, label: 'global.documents.internalDocument', callAux: true, model: true },
                        { id: 'template', type: 'select', order: 0, label: 'global.literals.template', dataType: 'LOCAL', data: $linq(GlobalDataFactory.templates).orderBy("x => x." + vm.languageColumn).toArray(), addAll: true, nameProperty: 'description', userChoosen: true },
                        { id: 'section', type: 'select', order: 1, label: 'global.literals.section', dataType: 'LOCAL', data: $linq(GlobalDataFactory.sections).orderBy("x => x." + vm.languageColumn).toArray(), addAll: true, nameProperty: vm.languageColumn, userChoosen: true },
                        { id: 'type', type: 'select', order: 2, label: 'global.literals.type', dataType: 'LOCAL', data: $linq(GlobalDataFactory.documentTypes).where("x => x.isPublic").toArray(), addAll: true, nameProperty: vm.languageColumn, userChoosen: true },
                        { id: 'origin', type: 'select', order: 3, label: 'global.literals.module', dataType: 'LOCAL', data: GlobalDataFactory.docModules, addAll: true, nameProperty: vm.languageColumn, callAux: true, userChoosen: true },
                        { id: 'createUser', type: 'text', order: 4, label: 'global.literals.user', callAux: true, userChoosen: true },
                        { id: 'meta', type: 'text', order: 5, label: 'global.literals.meta', callAux: true, userChoosen: true },
                        { id: 'modifyDdate', type: 'dateRange', order: 6, label: 'global.literals.modified', callAux: true, userChoosen: true },
                        { id: 'dossierNumber', type: 'text', order: 7, label: 'global.literals.numExp', callAux: true, userChoosen: true },
                        { id: 'docStatus', type: 'select', order: 8, label: 'global.literals.status', callAux: true, dataType: 'LOCAL', data: $linq(GlobalDataFactory.documentStatuses).orderBy("x => x.orderStatus").toArray(), addAll: true, nameProperty: 'nameLiteral', userChoosen: true }
                    ],
                    tableDefinition: {
                        id: 'tableAdvancedSearchDocumentsPopupOrderPoints',
                        origin: 'doc',
                        objectType: 'Document',
                        sortName: 'modifyDate',
                        sort: [[5, 'desc']],
                        columns: [
                        	{ id: 'docStatus', width: '10%', title: $filter('translate')('global.literals.status'), render: docStatusRender, sortable: false },
                            { id: 'type', width: '10%', column: showStyledDocumentTypeAcronym ? new IconColumn($filter, 'global.literals.type', 'acronym', vm.languageColumn) : new ObjectTextColumn($filter, 'global.literals.type', 'acronym') , sortable: false },
                            { id: 'id', width: '10%', column: new UIRouterStateColumn($filter, 'global.literals.code', 'annexa.doc.documents.view({code: \'[data]\'})', undefined, 'var code = full.id; if(full.code) { code = full.code.substr((full.code.indexOf(full.id) - 2), full.id.toString().length + 2); } code;') },
                            { id: 'id', column: new UIRouterStateColumnNoLink($filter, 'global.literals.title', 'annexa.doc.documents.view({code: \'[data]\'})', 'name')  },
                            { id: 'createUser', width: '20%', column: new UserColumn($filter, 'global.literals.user') },
                            { id: 'modifyDate', width: '20%', column: new DateTimeColumn($filter, 'global.literals.modifyDate') }
                        ], 
                        noLoading: true,
                        skipFirstSearch: true,
                        callAuxToAdd: { added: $linq(vm.documents).select("x => x.document.id").toArray() }
                    },
                    multiple: true
                }

                AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASDocumentsPopupOrderPoints', advancedSearchModal);
            }
            
            $scope.$on('annexaAdvancedSearchModalAdd', function(event, args){
            	if(args && args.id && args.id == 'modalASDocumentsPopupOrderPoints' && args.selected && args.selected.length > 0 && vm.canEdit){
        			vm.completeAdd(args.selected);
                    args.modal.close();
            	}
            });
            
            vm.completeAdd = function(selected){
        		if(selected && selected.length > 0){
            		_.forEach(selected, function(obj){
            			vm.documents.push({document:JSOG.decode(obj)});
            		});
            		if(vm.saveDocuments){
	            		vm.saveDocuments(vm.documents, undefined, vm.model);
	            	}
            	}
            }
            
            vm.deleteFunction = function(documentId){
            	var indexElement = undefined;
            	if(documentId){
            		indexElement = $linq(vm.documents).indexOf("x => x.id == "+documentId);
            		vm.documents.splice(indexElement, 1);
            	}
            	if(vm.saveDocuments){
            		vm.saveDocuments(vm.documents, undefined, vm.model);
            	}
            }
            
            vm.getDocStatusTitle = function (document) {
            	if(document && document.docStatus){
            		return 'global.documentState.' + document.docStatus;
            	}else{
            		return '';
            	}
            }
            
            vm.getDocStatusIcon = function (document) {
            	if(document && document.docStatus){
	                switch (document.docStatus) {
	                    case 'NEW':
	                        return 'fa fa-circle-o text-blue fa-lg';
	                        break;
	                    case 'NEW_AND_ATTACHMENT':
	                        return 'fa fa-adjust text-blue fa-lg';
	                        break;
	                    case 'PENDING_SIGN':
	                        return 'fa fa-circle text-blue fa-lg';
	                        break;
	                    case 'SIGNED':
	                        return 'fa fa-circle text-success fa-lg';
	                        break;
	                    case 'REJECTED':
	                        return 'fa fa-circle text-danger fa-lg';
	                        break;
	                    case 'COMPLETE':
	                        return 'fa fa-dot-circle-o text-success fa-lg v-t';
	                        break;
	                    case 'ARCHIVED':
	                        return 'fa fa-circle-o text-success fa-lg v-t';
	                        break;
	
	                }
            	}else{
            		return '';
            	}
            }
            
            this.$onInit = function(){
            	if(!vm.documents){
            		vm.documents = [];
            	}
            	if(!vm.module){
            		vm.module = 'SEC';
            	}
            	vm.searchedValue = undefined;
            	if(vm.canEdit == null || vm.canEdit == undefined){
            		vm.canEdit = true;
            	}
            }
            
        }],
        bindings: {
            documents: '=?',
            canEdit: '=?',
            saveDocuments: '=?',
            module: '=?',
            model: '=?'
            	
        }
    })