angular
    .module('annexaApp')
    .component('annexaBoxSecNotifications',{
        templateUrl: './components/sec/annexa-box-sec-notifications/annexa-box-sec-notifications.html',
        controller:['AnnexaFormlyFactory', 'NotificationFactory', '$scope', 'GlobalDataFactory', '$rootScope', function(AnnexaFormlyFactory, NotificationFactory, $scope, GlobalDataFactory, $rootScope) {
            var vm = this;
			vm.regOutputModule = [];
			vm.notificationModule = true;
			vm.boxTitle = 'global.literals.notifications';
            this.$onInit = function () {
                vm.regOutputModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.reg.output'").toArray();
                if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.show_notification_functionality){
                    vm.notificationModule = $rootScope.app.configuration.show_notification_functionality.value;
                }
            };
        }],
        bindings: {
            permissions: '=',
            content: '=',
            emptyText: '@'
        }
    })
    .component('annexaBoxSecNotificationsRender', {
        templateUrl: './components/sec/annexa-box-sec-notifications/annexa-box-sec-notifications-render.html',
        controller: ['$state', 'NotificationFactory', 'Language', 'AnnexaFormlyFactory', 'DialogsFactory','$filter', 'RestService', '$scope', function($state, NotificationFactory, Language, AnnexaFormlyFactory, DialogsFactory, $filter, RestService, $scope) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();

			vm.notificationGlobalStatus = angular.copy(NotificationFactory.notificationGlobalStatus);
            vm.notificationGlobalStatus.unshift({id: 'NO_SEND', name: $filter('translate')('NO_SEND'), class: 'text-grey', icon: 'circle', style: 'text-grey'});
                
            vm.dossierTransactionStatus = [];
            vm.dossierTransactionStatus.push({id: 'NO_CREATED', name: $filter('translate')('NO_CREATED'), class: 'text-grey', icon: 'circle', style: 'text-grey'});
            vm.dossierTransactionStatus.push({id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS'), class: 'text-warn', icon: 'circle', style: 'text-warn'});
            vm.dossierTransactionStatus.push({id: 'FINISHED', name: $filter('translate')('FINISHED'), class: 'text-success', icon: 'circle', style: 'text-success'});
            
            vm.viewNotification = function (notificationId) {
                 RestService.findOne('Notification', notificationId).then(function (data) {
                        var decodedData = JSOG.decode(data.data);
                    	var notificationViewModal = NotificationFactory.notificationViewModal(decodedData);

                        AnnexaFormlyFactory.showAnnexaFormModal('modalViewNotification', notificationViewModal);    
                    }).catch(function (error) {
                    	DialogsFactory.error('global.sec.literals.canNotOpenNotification')
                    });
            }

		    vm.getIconClassNotificationStatus = function (status) {
                switch (status) {
                    case 'PENDING':
                        return 'text-warn';
                    case 'SEND':
                        return 'text-info';
                    case 'COMPLETE':
                        return 'text-success';
                    case 'ERROR':
                        return 'text-danger';
                }

                return 'text-grey';
            }

			vm.getNameNotificationStatus = function (status) {
                switch (status) {
                    case 'PENDING':
                        return  $filter('translate')('PENDING');
                    case 'SEND':
                        return $filter('translate')('global.literals.SEND');
                    case 'COMPLETE':
                        return $filter('translate')('global.literals.COMPLETE');
                    case 'ERROR':
                        return $filter('translate')('global.literals.ERROR');
                }

                return $filter('translate')('NO_SEND');
            }

			vm.getIconClassTramStatus = function (dt) {
				if(dt && dt.id){
					if(dt.endDate){
						return 'success';
					}else{
						return 'warn';
					}
				}else{
					return 'text-grey';
				}
            }

			vm.getNameTramStatus = function (dt) {
                if(dt && dt.id){
					if(dt.endDate){
						return $filter('translate')('FINISHED');
					}else{
						return $filter('translate')('IN_PROGRESS');
					}
				}else{
					return $filter('translate')('NO_CREATED');
				}
            }
			
			$scope.$on('updateNotificationState', function(event, args) {
				if(args && args.id && args.globalStatus){
					_.forEach(vm.content, function(cont){
						if(cont.not && cont.not.id == args.id){
							cont.not.globalStatus = args.globalStatus;
						}
					});
				}
	        });
                                    

        }],
        bindings: {
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            origin: '@'
        }
    })