/**
 * Created by osirvent on 04/05/2017.
 */
angular
    .module('annexaApp')
    .component('annexaOrganMembers', {
        templateUrl: './components/sec/annexa-organ-members/annexa-organ-members.html',
        controller: ['CommonService', 'Language', 'RestService', '$rootScope', '$filter', 'RestService', 'CommonAdminModals', 'AnnexaFormlyFactory', 'DialogsFactory','$scope', 'GlobalDataFactory', 'CacheFactory', 
        function (CommonService, Language, RestService, $rootScope, $filter, RestService, CommonAdminModals, AnnexaFormlyFactory, DialogsFactory, $scope, GlobalDataFactory, CacheFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.addOrganMember = function () {
            	var modal = angular.copy(CommonAdminModals.organMemberNew);
                modal.annexaFormly.model = {};
                modal.annexaFormly.options = { watchAllExpressions: true };
                if( modal.annexaFormly.fields && modal.annexaFormly.fields.length > 0 && modal.annexaFormly.fields[0].fieldGroup && modal.annexaFormly.fields[0].fieldGroup.length > 2 && modal.annexaFormly.fields[0].fieldGroup[2]){
                	modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.existSecretary = ($linq(vm.members).count("x => x.memberRol.id == "+$rootScope.app.configuration.sec_organ_member_rols.secretary) > 0);
                	modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.existPresident = ($linq(vm.members).count("x => x.memberRol.id == "+$rootScope.app.configuration.sec_organ_member_rols.president) > 0);
                	modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.added = $linq(vm.members).select("x => x.organMemberGlobal.id").toArray();
                }
                AnnexaFormlyFactory.showModal('modalNewOrganMember', modal, vm.saveOrganMember, false);
            }
            
            vm.saveOrganMember = function(organMember){
            	if(organMember && organMember.annexaFormly && organMember.annexaFormly.model && organMember.annexaFormly.model.row_member){
		        	var member = {
		        		organMemberGlobal:((organMember.annexaFormly.model.row_member.organMemberGlobal)?organMember.annexaFormly.model.row_member.organMemberGlobal:undefined),
		        		attendeeAddressConvene:((organMember.annexaFormly.model.row_member.attendeeAddressConvene)?organMember.annexaFormly.model.row_member.attendeeAddressConvene:undefined),
		        		memberRol:((organMember.annexaFormly.model.row_member.memberRol)?organMember.annexaFormly.model.row_member.memberRol:undefined),
		        		vote:((organMember.annexaFormly.model.row_member.vote)?organMember.annexaFormly.model.row_member.vote:false),
		        		orderView:((organMember.annexaFormly.model.row_member.orderView)?organMember.annexaFormly.model.row_member.orderView:((vm.members && vm.members.length)?$linq(vm.members).select("x=>x.orderView").max()+1:1))
		        	};
		        	if(!vm.isNew){
		        		if(member){
		        			var memberAux = angular.copy(member);
		        			memberAux.organMemberGlobal=((member.organMemberGlobal)?{id:member.organMemberGlobal.id}:undefined);
		        			memberAux.attendeeAddressConvene=((member.attendeeAddressConvene)?{id:member.attendeeAddressConvene.id}:undefined);
		        			memberAux.memberRol=((member.memberRol)?{id:member.memberRol.id}:undefined);
		        			memberAux.vote=((member.vote)?member.vote:false);
		        			memberAux.orderView=((member.orderView)?member.orderView:-1);
		        			memberAux.organ= {id: vm.organId};
                    		RestService.insert('./api/sec/organ_member', memberAux).then(function(data) {
                    			member.id = data.id;
                    			member.organ = data.organ;
                    			member.orderView = data.orderView;
                    			member.createdDate = data.createdDate;
                    			if(!vm.members){
                                	vm.members = [];
                                }
                                vm.members.push(member);
                                _.forEach(vm.members, function(mem){
                            		if(data.id != mem.id && mem.orderView >= data.orderView){
                            			mem.orderView++;
                            		}
                            	});
                            	organMember.close();
                            }).catch(function() {
                            	organMember.alerts.push("Error");
                            });
                        }
		        	}else{
		        		if(vm.members){
		        			_.forEach(vm.members, function(mem){
                    			if(mem.orderView >= member.orderView){
                    				mem.orderView++;
                    			}
                    		});
		        		}
		        		vm.members.push(member);
		        		organMember.close();
		        	}
            	}
            }

            vm.editOrganMember = function (member) {
            	var modal = angular.copy(CommonAdminModals.organMemberNew);
            	var memberToUpdate = angular.copy(member);
            	if(memberToUpdate.organMemberGlobal && memberToUpdate.organMemberGlobal.attendee && memberToUpdate.organMemberGlobal.attendee.completeName){
            		memberToUpdate.organMemberGlobal.completeName = memberToUpdate.organMemberGlobal.attendee.completeName
            	}
            	modal.annexaFormly.model = {row_member: memberToUpdate};
            	modal.data = memberToUpdate.orderView;
                modal.annexaFormly.options = { watchAllExpressions: true };
                if( modal.annexaFormly.fields && modal.annexaFormly.fields.length > 0 && modal.annexaFormly.fields[0].fieldGroup && modal.annexaFormly.fields[0].fieldGroup.length > 2 && modal.annexaFormly.fields[0].fieldGroup[2]){
                    modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.existSecretary = ($linq(vm.members).count("x => x.organMemberGlobal.id != "+memberToUpdate.organMemberGlobal.id+" && x.memberRol.id == "+$rootScope.app.configuration.sec_organ_member_rols.secretary) > 0);
                    modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.existPresident = ($linq(vm.members).count("x => x.organMemberGlobal.id != "+memberToUpdate.organMemberGlobal.id+" && x.memberRol.id == "+$rootScope.app.configuration.sec_organ_member_rols.president) > 0);
                	modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.added = $linq(vm.members).select("x => x.organMemberGlobal.id").toArray();
                }
            	AnnexaFormlyFactory.showModal('modalNewOrganMember', modal, vm.updateOrganMember, false);
            }
            
            vm.updateOrganMember = function(organMember){
            	if(organMember && organMember.annexaFormly && organMember.annexaFormly.model && organMember.annexaFormly.model.row_member){
		        	var member = angular.copy(organMember.annexaFormly.model.row_member);
		        	if(member){
		        		var memberIndex = -1;
		        		if(member.id){
				        	memberIndex = $linq(vm.members).indexOf("x => x.id == "+member.id);
		        		}else{
				        	memberIndex = $linq(vm.members).indexOf("x => x.organMemberGlobal && x.organMemberGlobal.id == "+member.organMemberGlobal.id);
		        		}
		        		if(memberIndex > -1){
				        	if(!vm.isNew){
				        		if(member){
				        			var memberAux = angular.copy(member);
				        			memberAux.organMemberGlobal=((member.organMemberGlobal)?{id:member.organMemberGlobal.id}:undefined);
				        			memberAux.attendeeAddressConvene=((member.attendeeAddressConvene)?{id:member.attendeeAddressConvene.id}:undefined);
				        			memberAux.memberRol=((member.memberRol)?{id:member.memberRol.id}:undefined);
				        			memberAux.vote=((member.vote)?member.vote:false);
				        			memberAux.orderView=((member.orderView)?member.orderView:-1);
				        			memberAux.organ= {id: vm.organId};
		        	            	
		                    		RestService.update('./api/sec/organ_member/' + memberAux.id, memberAux).then(function (data) {
		                    			if(memberIndex || memberIndex == 0){
			                    			if(vm.members && vm.members.length >= memberIndex){
			                                	vm.members[memberIndex] = member;
			                                	if(data && organMember.data != data.orderView){
			                                		_.forEach(vm.members, function(mem){
			                                			if(data.id != mem.id){ 
			                                			    if(organMember.data > data.orderView){
																if(mem.orderView >= data.orderView && mem.orderView <= organMember.data){
																	mem.orderView++;
																}
			                                			    }else{
			                                			    	if(mem.orderView >= organMember.data && mem.orderView <= data.orderView){
																	mem.orderView--;
																}
			                                			    }	                                			    
			                                			}
			                                		});
			                                	}
			                                }
		                    			}
		                    			organMember.close();
		                            }).catch(function () {
		                            	organMember.alerts.push("Error");
		                            });
		                        }
				        	}else{
				        		if(vm.members){
				        			_.forEach(vm.members, function(mem){
                        			    if(organMember.data > member.orderView){
											if(mem.orderView >= member.orderView && mem.orderView <= organMember.data){
												mem.orderView++;
											}
                        			    }else{
                        			    	if(mem.orderView >= organMember.data && mem.orderView <= member.orderView){
												mem.orderView--;
											}
                        			    }	                                			    
                            		});
				        		}
				        		vm.members[memberIndex] = member;
				        		organMember.close();
				        	}
			        	}
		        	}
            	}
            }
            
            vm.deleteOrganMember = function (member) {
               	DialogsFactory.confirm('global.sec.literals.confirmRemoveOrganMemberTitle', 'global.sec.literals.confirmRemoveOrganMemberBody').then(function (dataAux) {
           			if(member){
		        		var memberIndex = -1;
		        		if(member.id){
				        	memberIndex = $linq(vm.members).indexOf("x => x.id == "+member.id);
		        		}else{
				        	memberIndex = $linq(vm.members).indexOf("x => x.organMemberGlobal && x.organMemberGlobal.id == "+member.organMemberGlobal.id);
		        		}
		        		var orderView = member.orderView;
		        		if(memberIndex > -1){
				        	if(!vm.isNew){
				        		if(member && member.id && ( memberIndex || memberIndex == 0) && vm.members && vm.members.length >= memberIndex){
		                    		RestService.delete('./api/sec/organ_member/' + member.id).then(function (data) {
		                    			vm.members.splice(memberIndex, 1);
	                    				_.forEach(vm.members, function(mem){
                                			if(mem.orderView >= orderView){
                                				mem.orderView--;
                                			}
                                		});
	                                }).catch(function (error) {
		                                console.log(error);
		                            });
		        	                
		                        }
				        	}else{
				        		vm.members.splice(memberIndex, 1);
				        		_.forEach(vm.members, function(mem){
                        			if(mem.orderView >= orderView){
                        				mem.orderView--;
                        			}
                        		});
				        	}
			        	}
		        	}
                }).catch(function (data) {
	                    //Empty
	            });
            }
            
            this.$onInit = function(){
            	if(vm.members && vm.members.length > 0){
	                _.forEach(vm.members, function (value, index) {
	                	if(value.attendeeAddressConvene && value.attendeeAddressConvene.id){
	                		var thirdAllAddress = new ThirdAddress(undefined, vm.languageColumn);
	                		value.attendeeAddressConvene.name = thirdAllAddress.getAddressHtml(value.attendeeAddressConvene, true, true);
	                	}
	                });
            	}
            }
            
        }],
        bindings: {
            members: '=',
            isNew: '=',
            organId:'=?'
        }
    })